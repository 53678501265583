$(document).ready(function() {

	// BOOTSTRAP TOOLTIPS INIT

	$('[data-toggle="tooltip"]').tooltip();	


	// BOOTSTRAP MODALS INIT

	// $('.modal').modal('show');


	// TOGGLE CART FA ICON INFO AGGIUNTIVE

	$( '.cart-more-info' ).on( 'shown.bs.collapse', function() {

		$(this)
			.find('.card-header i.fa-angle-down')
			.removeClass('fa-angle-down')
			.addClass('fa-angle-up');

	});

	$( '.cart-more-info' ).on( 'hidden.bs.collapse', function() {

		$(this)
			.find('.card-header i.fa-angle-up')
			.removeClass('fa-angle-up')
			.addClass('fa-angle-down');

	});


	// REPLACE FONT AWESOME ICON ON CHECKOUT BREADCRUMBS STEP COMPLETED

	$('.shipping-breadcrumbs-step.complete .shipping-breadcrumbs-dot > span').empty().html('<i class="fa fa-check" aria-hidden="true"></i>');


	// HOVER PRODUCT BOX

	// $('.product-box').hover(function() {
	// 	$(this).addClass('hover');
	// });
	// $('.product-box').mouseleave(function() {
	// 	$(this).removeClass('hover');
	// });

	// STICKY NAVBAR
	// Custom function which toggles between sticky class (is-sticky)
	var stickyToggle = function (sticky, stickyWrapper, scrollElement) {
		var stickyHeight = sticky.outerHeight();
		var stickyTop = stickyWrapper.offset().top;
		if (scrollElement.scrollTop() >= stickyTop) {
			stickyWrapper.height(stickyHeight);
			sticky.addClass("is-sticky");
		}
		else {
			sticky.removeClass("is-sticky");
			stickyWrapper.height('auto');
		}
	};
	// Find all data-toggle="sticky-onscroll" elements
	$('[data-toggle="sticky-onscroll"]').each(function () {
		var sticky = $(this);
		var stickyWrapper = $('<div>').addClass('sticky-wrapper'); // insert hidden element to maintain actual top offset on page
	    sticky.before(stickyWrapper);
	    sticky.addClass('sticky');
	    // Scroll & resize events
	    $(window).on('scroll.sticky-onscroll resize.sticky-onscroll', function () {
	    	stickyToggle(sticky, stickyWrapper, $(this));
	    });
	    // On page load
	    stickyToggle(sticky, stickyWrapper, $(window));
	});


	// BOOTSTRAP OFF CANVAS MENU

	$('[data-toggle="offcanvas"]').on('click', function () {
		$('.offcanvas-collapse').toggleClass('open');
	});	



	// BACK TO TOP

	function detectPosition(top){
		if($(window).scrollTop() > top){
			return 1
		}else{
			return 0;
		}
	}
	
	function fadeTop(x){
		if(x==1){
			$('#top').fadeIn(300)
		}else{
			$('#top').fadeOut(300)
		}
	}

	function actionTop(top){
		x = detectPosition(top);
		fadeTop(x)
	}

	if($('#top').length > 0){

		var top = 220;
		var x = 0;
		actionTop(top)

		$(window).scroll(function(){
			actionTop(top)
		})

		$('#top').click(function(e){
			e.preventDefault();
			$('html,body').animate({'scrollTop':0},750);
		});
	};

});